<template>
  <div class="main">
    <van-form ref="addForm" class="form">
      <!-- 车牌号 -->
      <van-field
        v-model="carForm.carNum"
        placeholder="请输入车牌号"
        required
        :rules="[{ required: true }]"
      >
        <span slot="label" class="field-label">车牌号</span>
      </van-field>

      <!-- 车辆颜色 -->
      <van-field
        v-model="carForm.carColour"
        placeholder="请输入车辆颜色"
        required
        :rules="[{ required: true }]"
      >
        <span slot="label" class="field-label">车辆颜色</span>
      </van-field>

      <!-- 车辆品牌 -->
      <van-field
        v-model="carForm.carBrand"
        placeholder="请输入车辆品牌"
        required
        :rules="[{ required: true }]"
      >
        <span slot="label" class="field-label">车辆品牌</span>
      </van-field>

      <!-- 车辆型号 -->
      <van-field
        v-model="carForm.carModel"
        placeholder="请输入车辆型号"
        required
        :rules="[{ required: true }]"
      >
        <span slot="label" class="field-label">车辆型号</span>
      </van-field>

      <!-- 车辆照片 -->
      <h2 class="category-title-label">
        车辆照片
      </h2>
      <van-field>
        <template #input>
          <van-uploader
            @oversize="oversize"
            v-model="imgList"
            :max-size="5 * 1024 * 1024"
            image-fit="fill"
            max-count="1"
            :after-read="uploadPictures"
          />
        </template>
      </van-field>

      <!-- 住户信息 -->
      <van-field
        v-model="carForm.remark"
        placeholder="请输入住户信息"
        type="textarea"
        rows="2"
        autosize
        required
        :rules="[{ required: true }]"
      >
        <span slot="label" class="field-label">住户信息</span>
      </van-field>

      <div style="margin: 0.375rem">
        <van-button block class="submit" @click="appointment" v-if="!isEdit" :loading="subLoading">
          提交
        </van-button>

        <div class="btns" v-else>
          <van-button block class="submit" @click="appointment" :loading="subLoading">
            修改
          </van-button>
          <van-button block class="submit" color="#f73f55" :loading="delLoading" @click="removeCar(carForm.id)" >
            删除
          </van-button>
        </div>
      </div>
    </van-form>
  </div>
</template>

<script>
import axios from '@/utils/axios'
import { Dialog } from 'vant';
import { addMyCar, getCarDetail, removeCar } from '@/api/houseApi.js'

export default {
  data() {
    return {
      subLoading: false,
      delLoading: false,
      imgList: [],
      carForm: {
        userId: this.$store.getters.userInfo.id,
        carPic: '',
      },
      // 新增 or 编辑
      isEdit: false,
    }
  },
  created() {
    let editId = this.$route.query.id
    this.isEdit = editId?true:false
    if(editId) {
      this.getDetail(editId)
    }
  },
  methods: {
    getDetail(id) {
      getCarDetail({id: id}).then(res => {
        if(res.code == 200) {
          this.carForm = res.data
          this.imgList.push({url: res.data.carPic})
        }
      })
    },
    removeCar(id) {
      Dialog.confirm({
        title: '标题',
        message: '确定删除车辆',
      })
        .then(() => {
          // on confirm
          this.delLoading = true
          removeCar({ids: id}).then(res => {
            if(res.code == 200) {
              this.delLoading = false
            }
          })
        })
        .catch(() => {
          // on cancel
        });
      
    },
    // 图片超出大小
    oversize() {
      this.$toast({
        type: 'warning',
        message: '图片不能超过5M~',
        duration: 500,
      })
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`
      } else if (type === 'month') {
        return `${val}月`
      } else if (type === 'day') {
        return `${val}日`
      }
      return val
    },
    // 提交表单回调
    appointment() {
      this.$refs.addForm.validate().then(() => {
        if (!this.carForm.carPic) {
          this.$toast({
            type: 'fail',
            message: '请上传车辆照片',
            duration: 1500,
          })
          return false
        }
        this.subLoading = true
        // 新增参数
        this.carForm.status = this.isEdit?null:1
        addMyCar(this.carForm)
          .then(res => {
            this.subLoading = false
            if (res.code == 200) {
              this.$toast({
                type: 'success',
                message: '操作成功',
                duration: 1500,
                onClose: () => {
                  this.$router.go(-1)
                },
              })
            } else {
              this.$toast({
                type: 'error',
                message: res.message,
                duration: 1500,
              })
            }
          })
          .catch(() => {
            this.subLoading = false
          })
      }).catch(() => {
      })
      

      
    },

    // 图片上传后，获取返回的参数，在提交时当做参数传递给后台，uploadImgList是图片上传后返回的图				片信息列表
    uploadPictures(file) {
      // 上传图
      file.status = 'uploading'
      file.message = '上传中'

      let url = file.file
      let formdata = new FormData()
      formdata.append('file', url)
      formdata.append('currentUser', 0)

      axios({
        baseURL: '/minio',
        url: '/minio/villageUpload?keepFileName=1&bucketName=village',
        method: 'post',
        data: formdata,
      })
        .then(res => {
          if (res.code == 200) {
            // this.carForm.carPic = []
            // this.carForm.carPic.push(res.data.url)
            this.carForm.carPic = res.data.url
            file.url = res.data.url
            file.status = 'done'
            file.message = ''
          } else {
            file.status = 'failed'
            file.message = '上传失败'
          }
        })
        .catch(() => {
          file.status = 'failed'
          file.message = '上传失败'
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.rowTab {
  width: 100%;
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
  .item {
    font-size: 12px;
    width: 50px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
  }
  .item.act {
    color: #1989fa;
    border: 1px solid #1989fa;
  }
}
.my_nav {
  >>> .van-nav-bar__left .van-icon-arrow-left.van-icon {
    font-size: 0.3rem;
  }
  >>> .van-nav-bar__title {
    font-size: 0.26rem;
  }
}
.main {
  .phone {
    margin-top: 0.075rem;
    >>> .van-field__label {
      width: auto;
    }
  }

  .error-img >>> .van-empty__image {
    width: 1.5rem;
    height: 1.5rem;
  }

  .form {
    margin: 0.15rem 0 0.975rem;
    background-color: #fff;
    .category-title-label {
      font-size: 0.37333rem;
      padding: 0.2rem 0;
      margin: 0 0.42667rem;
      color: #646566;
      text-align: left;
      word-wrap: break-word;
      font-weight: 400;
      position: relative;
      &::before {
        content: '*';
        position: absolute;
        left: -8px;
        top: 28%;
        color: #ee0a24;
        font-size: 14px;
      }
    }
    .category-title {
      font-size: 14px;
      padding: 0.2rem 0;
      margin: 0 0.32rem;
      border-bottom: 0.02rem solid #ebedf0;
      &::before {
        content: '';
        display: inline-block;
        width: 3px;
        height: 14px;
        margin-right: 10px;
        vertical-align: middle;
        background: #48a66e;
      }
    }
    .field-left-icon {
      width: 0.375rem;
      font-size: 0.28rem;
    }
    .send {
      color: #1989fa;
    }

    .submit {
      background: #1989fa;
      color: white;
      border-radius: 5px;
    }
  }
}
.center-find-user {
  position: fixed;
  width: 100%;
  top: 50%;
  height: 180px;
  margin-top: -100px;
  text-align: center;
  .icon-find-user-by-tel {
    width: 2rem;
    height: 2rem;
    color: #fff;
    background-color: #48a66e;
    font-size: 1.4rem;
    border-radius: 50%;
    line-height: 2rem;
  }
  .find-input {
    width: 80%;
    margin: 20px auto;
  }
}
.btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
